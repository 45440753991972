import "./App.scss";
import React, { Suspense } from "react";
import {
  Route,
  RouterProvider,
  createBrowserRouter,
  createRoutesFromElements,
} from "react-router-dom";

// const Practice = React.lazy(() => import("./Components/Home/Practice/Practice"));
const MainPage = React.lazy(() => import("./Components/mainPage"));
// const Home = React.lazy(() => import("./Components/Home/Home"));
// const Faq = React.lazy(() => import("./Components/Faq/Faq"));
// const Affiliate = React.lazy(() => import("./Components/Affilate/Affilate"));
// const Blog = React.lazy(() => import("./Components/Blog/Blog"));
// const Economic = React.lazy(() => import("./Components/Economic/Page"));
// const Challenges = React.lazy(() =>
//   import("./Components/Challenges/Challenges")
// );
// const Aboutus = React.lazy(() => import("./Components/Aboutus/Aboutus"));
// const BlogDetail = React.lazy(() =>
//   import("./Components/Blog/BlogDeatils/BlogDeatils")
// );
// const Courses = React.lazy(() => import("./Components/Courses/Courses"));
// const PrivacyPolicy = React.lazy(() => import("./Components/PrivacyPolicy/PrivacyPolicy"));
// const TermsAndConditions = React.lazy(() => import("./Components/TermsAndConditions/TermsAndConditions"));
const ErrorPage = React.lazy(() => import("./Components/ErrorPage/Error"));

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route>
      <Route path="/" element={<MainPage />}>
        {/* <Route path="/faq" element={<Faq />}></Route>
        <Route path="/about-us" element={<Aboutus />}></Route>
        <Route path="/economic" element={<Economic />}></Route>
        <Route path="/challenges" element={<Challenges />}></Route>
        <Route path="/blog" element={<Blog />}></Route>
        <Route path="/affiliate" element={<Affiliate />}></Route>
        <Route path="/blogdetail" element={<BlogDetail />}></Route>
        <Route path="/courses" element={<Courses />}></Route>
        <Route path="/privacyPolicy" element={<PrivacyPolicy />}></Route>
        <Route path="/termsAndConditions" element={<TermsAndConditions />}></Route> */}
        {/* <Route path="" element={<Home />}></Route> */}
        <Route path="" element={<ErrorPage />}></Route>
      </Route>
    </Route>
  )
);

function App() {
  return (
    <div className="App" loading="lazy">
      <div className="Page"></div>
      <Suspense>
        <RouterProvider router={router}></RouterProvider>
      </Suspense>
    </div>
  );
}

export default App;
