import i18next from "i18next";
import { initReactI18next } from "react-i18next";

import en from "./Language/eng.json";
import ar from "./Language/ar.json";
import ru from "./Language/russian.json";
import du from "./Language/Dutch.json";
import fr from "./Language/French.json";
import sp from "./Language/Spanish.json";


i18next.use(initReactI18next).init({
	resources: {
		en: {
			translation: en,
		},
		ar: {
			translation: ar,
		},
        ru: {
			translation: ru,
		},
		du: {
			translation: du,
		},
        fr: {
			translation: fr,
		},
		sp: {
			translation: sp,
		},
	},
	lng: localStorage.getItem("lng") || "du",
});

export default i18next;